// import pic1 from "../../images/gallery/pic-1.JPG"
// import pic2 from "../../images/gallery/pic-2.JPG"
// import pic3 from "../../images/gallery/pic-3.JPG"
// import pic4 from "../../images/gallery/pic-4.JPG"
// import pic5 from "../../images/gallery/pic-5.JPG"
import pic6 from "../../images/gallery/pic-6.JPG"
import pic29 from "../../images/about-bg.png"
import pic7 from "../../images/gallery/pic-7.jpeg"
import pic8 from "../../images/gallery/pic-8.jpeg"
import pic9 from "../../images/gallery/new/pic-1.jpeg"
import pic11 from "../../images/gallery/new/pic-3.jpeg"
import pic13 from "../../images/gallery/new/pic-5.jpeg"
import pic14 from "../../images/gallery/new/pic-6.jpeg"
import pic15 from "../../images/gallery/new/pic-7.jpeg"
import pic16 from "../../images/gallery/new/pic-8.jpeg"
import pic17 from "../../images/gallery/new/pic-9.jpeg"
import pic18 from "../../images/gallery/new/pic-10.jpeg"
import pic19 from "../../images/gallery/new/pic-11.jpeg"
import pic20 from "../../images/gallery/new/pic-12.jpeg"
import pic1 from "../../images/gallery/new/pic-13.jpeg"
import pic2 from "../../images/gallery/new/pic-14.jpeg"
import pic3 from "../../images/gallery/new/pic-15.jpeg"
import pic4 from "../../images/gallery/new/pic-16.jpeg"
import pic5 from "../../images/gallery/new/pic-17.jpeg"
import pic22 from "../../images/gallery/new/pic-19.jpeg"
import pic23 from "../../images/gallery/new/pic-20.jpeg"
import pic25 from "../../images/gallery/new/pic-22.jpeg"
import pic26 from "../../images/gallery/new/pic-23.jpeg"
import pic27 from "../../images/gallery/new/pic-24.jpeg"
import pic28 from "../../images/gallery/new/pic-25.jpeg"

export const characters = [
    {
        "id" : 1,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic1,
    },
    {
        "id" : 2,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic2,
    },
    {
        "id" : 3,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far ",
        "image" : pic7,
    },
    {
        "id" : 4,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic4,
    },
    {
        "id" : 5,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic3,
    },
    {
        "id" : 6,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic6,
    },
    {
        "id" : 7,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic8,
    },
    {
        "id" : 8,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic5,
    },
    {
        "id" : 9,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic9,
    },
    {
        "id" : 11,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic11,
    },
    {
        "id" : 13,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic13,
    },
    {
        "id" : 14,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic14,
    },
    {
        "id" : 15,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic15,
    },
    {
        "id" : 16,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic16,
    },
    {
        "id" : 27,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic27,
    },
    {
        "id" : 28,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic28,
    },
    {
        "id" : 17,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic17,
    },
    {
        "id" : 18,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic18,
    },
    {
        "id" : 19,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic19,
    },
    {
        "id" : 20,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic20,
    },
    {
        "id" : 23,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic23,
    },
    {
        "id" : 22,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic22,
    },
    {
        "id" : 25,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic25,
    },
    {
        "id" : 29,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic29,
    },
    {
        "id" : 26,
        "name" : "CypherCrescent Industry School",
        "description" : "Images from events so far",
        "image" : pic26,
    },
]