import React, { useEffect, useState } from "react";
import PayButtonModal from "../../components/PayButtonModal/PayButtonModal";
import SignupForm from "../../components/SignupForm/SignupForm";
import WaitingForm from "../../components/WaitingForm/WaitingForm";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";

const Program = () => {
  const [programDetails, setProgramDetails] = useState({});
  // const [show, setShow] = useState(false);
  const [buttonShow, setButtonShow] = useState(false);

  const [showWaitListForm, setshowWaitListForm] = useState(false);
  const [showSignupForm, setSignupForm] = useState(false);
  // const [radioValue, setRadioValue] = useState('virtual');

  const handleCloseWaitListForm = () => setshowWaitListForm(false);
  const handleShowWaitListForm = () => setshowWaitListForm(true);

  // const getPrice = (selectedLocation) => {
  //     let a = programDetails.location.find(element => element.place === selectedLocation);
  //     return a.price
  // }

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  useEffect(() => {
    let program = localStorage.getItem("programDetails");
    setProgramDetails(JSON.parse(program));
  }, []);
  return (
    <PageWrapper>
      <section className="pt-200 my-5 program-page">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="program text-center">
                <img src={programDetails.image} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center">
              <div>
                <h2 className="text-primary">{programDetails.name}</h2>
                <p>{programDetails.description}</p>
                {/* <h5 className='mt-3'>&#36; {programDetails.price}</h5> */}
                {/* <h5 className='mt-3'>&#36; {!programDetails.location ?programDetails.price: getPrice(radioValue)}</h5> */}
                {/* <p>Location: {programDetails.location}</p> */}
                {programDetails.location ? (
                  <div className="mb-3">
                    <p>Select your plan</p>
                    {/* {programDetails.location.map((e, index) => (
                                        <div key={index}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={e.place} checked={radioValue === e.place}
                                                    onChange={(e) => setRadioValue(e.currentTarget.value)} />
                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    {e.place}
                                                </label>
                                            </div>
                                        </div>
                                    ))} */}
                  </div>
                ) : (
                  ""
                )}

                {programDetails.availableSoon === true ? (
                  <div>
                    <p className="fw-bold">Course will be available soon.</p>
                    <a
                      href="#link"
                      className="btn btn-primary"
                      onClick={handleShowWaitListForm}
                    >
                      Join waiting list
                    </a>{" "}
                  </div>
                ) : (
                  <div>
                    <div className="d-flex">
                      {programDetails.registerLink ? (
                        <a
                          href={programDetails.registerLink}
                          class="btn btn-outline-primary me-2"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Register
                        </a>
                      ) : (
                        <button
                          className="btn btn-outline-primary me-3"
                          onClick={() => setSignupForm(true)}
                        >
                          Register
                        </button>
                      )}

                      {/* <button className="btn btn-primary" onClick={handleShow}>Pay for Course</button> */}
                      {/* <button className="btn btn-primary" onClick={() => { setButtonShow(true) }}>Pay for Course</button> */}
                    </div>
                  </div>
                )}
                {/* <p className='mt-5'>The Lagos and Uyo short courses can also be taken out of the country at $4500 per person</p> */}
              </div>
            </div>
          </div>
          <div className="py-5">
            {/* <h2 className='section-heading'>About Course</h2> */}
            {/* <div dangerouslySetInnerHTML={{ __html: programDetails.note }}>
                        </div> */}
            {programDetails.why ? (
              <div>
                <h4 className="text-primary">Why choose this training?</h4>
                <p>{programDetails.why} </p>{" "}
              </div>
            ) : (
              ""
            )}
            <div>
              {programDetails.whyShould && (
                <div>
                  <h4 className="text-primary">
                    Why Should I Take This Training?
                  </h4>
                  <p>{programDetails.whyShould}</p>
                </div>
              )}
            </div>

            <div>
              {programDetails.objectives !== "" ? (
                <div>
                  <h4 className="text-primary">Objectives of the training?</h4>
                  <p>
                    At the end of this training, the participant will be able
                    to:
                  </p>
                  <ul
                    dangerouslySetInnerHTML={{
                      __html: programDetails.objectives,
                    }}
                  ></ul>
                </div>
              ) : (
                ""
              )}

              <h4 className="text-primary">Who is this training for?</h4>
              <p>{programDetails.trainingFor}</p>
              <h6>The target audience is</h6>
              <ul
                dangerouslySetInnerHTML={{
                  __html: programDetails.professionals,
                }}
              ></ul>
              {/* {programDetails.participantLearn ? (
                <div className="mt-4">
                  <h4 className="text-primary">
                    What participants will learn{" "}
                  </h4>
                  <p>At the end of this training, participants should</p>
                  <ul
                    dangerouslySetInnerHTML={{
                      __html: programDetails.participantLearn,
                    }}
                  ></ul>
                </div>
              ) : (
                ""
              )} */}

              <div>
                {programDetails.gain && (
                  <div>
                    <h4 className="text-primary">
                      What do I stand to gain from this training?
                    </h4>
                    <ul
                      dangerouslySetInnerHTML={{
                        __html: programDetails.gain,
                      }}
                    ></ul>
                  </div>
                )}
              </div>
              <div>
                {programDetails.duration && (
                  <div>
                    <h4 className="text-primary">
                      What’s the duration of the training?
                    </h4>
                    <p>{programDetails.duration}</p>
                  </div>
                )}
              </div>
              <h4 className="text-primary">
                How will the training be presented?
              </h4>
              <p>{programDetails.presentation}</p>
              <div>
                {programDetails.price && (
                  <div>
                    <h4 className="text-primary">Training Fee</h4>
                    <h5>${programDetails.price}</h5>
                  </div>
                )}
              </div>
              {programDetails.instructors ? (
                <section className="mt-4">
                  {programDetails.instructors.length > 0 ? (
                    <div>
                      {" "}
                      <h4 className="text-primary mb-5">Course Facilitators</h4>
                      <div className="row">
                        {programDetails.instructors.map((instructor) => {
                          const { id, name, profile, image } = instructor;
                          return (
                            <div className="mb-5" key={id}>
                              <div className="row">
                                <div className="col-md-4 mb-3 d-flex justify-content-center align-items-center">
                                  <div>
                                    <img
                                      src={image}
                                      className="img-fluid instructor-img"
                                      alt={name}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-8 mb-3 d-flex align-items-center">
                                  <div>
                                    <h5 className="fw-bold mb-3">{name}</h5>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: profile,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </section>
              ) : (
                ""
              )}

              {programDetails.refundPolicy ? (
                <div className="mt-4">
                  <h4 className="text-primary">
                    Cancellation and Refund Policy
                  </h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: programDetails.refundPolicy,
                    }}
                  ></div>
                </div>
              ) : (
                ""
              )}
              <div>
                {programDetails.expert && (
                  <div>
                    <h4 className="text-primary">
                      Can I really become an expert in six months?
                    </h4>
                    <p>{programDetails.expert}</p>
                  </div>
                )}
              </div>

              <div className="py-4">
                <div className="row">
                  <div className="col-md-6">
                    <h2 className="mb-3">Register for {programDetails.name}</h2>
                    {programDetails.availableSoon === true ? (
                      <div>
                        <p className="fw-bold">
                          Course will be available soon.
                        </p>
                        <a
                          href="#link"
                          className="btn btn-primary"
                          onClick={handleShowWaitListForm}
                        >
                          Join waiting list
                        </a>{" "}
                      </div>
                    ) : (
                      <div>
                        <div className="d-flex">
                          {programDetails.registerLink ? (
                            <a
                              href={programDetails.registerLink}
                              class="btn btn-outline-primary me-2"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Register
                            </a>
                          ) : (
                            <button
                              className="btn btn-outline-primary me-3"
                              onClick={() => setSignupForm(true)}
                            >
                              Register
                            </button>
                          )}
                          {/* <button className="btn btn-primary" onClick={handleShow}>Pay for Course</button> */}
                          {/* <button className="btn btn-primary" onClick={() => { setButtonShow(true) }}>Pay for Course</button> */}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-7">
                    {/* 
                                            <h4>Training Perks</h4>
                                            <p>Training benefits include:</p>
                                            <ul>
                                                <li>kjjfk</li>
                                            </ul> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <Paystack show={show} handleClose={handleClose} amount={programDetails.price + "00"} courseName={programDetails.name} ProductLink={programDetails.link} /> */}
            <WaitingForm
              show={showWaitListForm}
              handleClose={handleCloseWaitListForm}
            />
            <SignupForm
              show={showSignupForm}
              handleClose={() => setSignupForm(false)}
            />
            <PayButtonModal
              show={buttonShow}
              handleClose={() => setButtonShow(false)}
              program={programDetails}
              selectedRadio={""}
            />
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};

export default Program;
