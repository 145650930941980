import bootcamp from "../../images/programs/main/inner-main/flyer-bootcamp.jpg";
import machineLearning from "../../images/programs/main/inner-main/ml.jpg";
import swAcademy from "../../images/programs/main/inner-main/sw-academy.jpg";
import reservoir from "../../images/programs/main/inner-main/mbaa-latest.jpg";
import cProgramming from "../../images/programs/main/inner-main/c-sharp.jpg";
import leadership from "../../images/programs/main/inner-main/sl.jpg";
import exploration from "../../images/programs/Exp_Prod_Contract.jpg";
import explorationBus from "../../images/programs/exploration-business.jpg";
import sepalBus from "../../images/programs/SEPAL-business-forcast.jpg";
import oilMgt from "../../images/programs/oil-and-gas-management.jpg";
import wrfmOpt from "../../images/programs/WRFM-optimization.jpg";
import prodSystem from "../../images/programs/production-system.jpg";
import geology from "../../images/programs/geology.jpg";
import landD from "../../images/programs/LandD.jpg";

// import images for the clients
import aust from "../../images/about/AUST.png";
import coe from "../../images/about/COE.png";
import coou from "../../images/about/COOU.png";
import eroton from "../../images/about/EROTON.png";
import futminna from "../../images/about/FUTMINNA.png";
import futo from "../../images/about/FUTO.png";
import idsl from "../../images/about/IDSL_.png";
import ips from "../../images/about/IPS.png";
import ndWestern from "../../images/about/ND_WESTERN.png";
import nile from "../../images/about/NILE.png";
import nnpc from "../../images/about/NNPC.png";
import pti from "../../images/about/PTI.png";
import spe from "../../images/about/SPE.png";
import uniben from "../../images/about/UNIBEN.png";
import unilag from "../../images/about/UNILAG.png";
import uniport from "../../images/about/UNIPORT.png";

// images for news
import appoint from "../../images/about/appoint.jpeg";
import websiteLaunch from "../../images/about/website-launch.jpeg";

// images for instructors

import tg from "../../images/programs/instructors/thankgod.png";
import francis from "../../images/programs/instructors/francis.png";
import davidogbe from "../../images/programs/instructors/davidogbe.jpeg";

export const programs = [
  {
    id: 1,
    name: "CypherCrescent Software Academy",
    description:
      "The CypherCrescent Software Academy (CCSA) is an intensive software training programme/bootcamp designed to build and grow a pool of tech talents by taking absolute beginners to proficiency level in a matter of months. The goal of the programme is to train young minds on the skills that can qualify them to work as software engineers in top tech companies around the world",
    image: swAcademy,
    price: "",
    objectives:
      "",
    trainingFor:
      "Do you like to solve problems? Do you like to push yourself beyond your limits? Do you like to constantly challenge the status-quo? Or perhaps none of these describes you but you’re just enthusiastic about software technology, and you’d like to explore if this career path will suit you. Then you’re in the right place.",
    presentation:
      "The training is designed to be completely virtual to ensure that participation is borderless. You can take part in the training and be fully involved in all course activities irrespective of your location. However, we may organize periodic meet-ups during the course of the programme for those residing in the same areas.",
    professionals:
      "<li>Pre-University student</li> <li>Recent graduates</li> <li>NYSC members</li> <li>Young professionals starting in their career in software development, or maybe switch from a different career path.</li> ",
    // "link": "https://paystack.com/buy/well-performance-analysis-qcmooi"
    registerLink: "https://bit.ly/software_acad",
    why: "",
    instructors: [],
    expert: 'Malcolm Gladwell’s 10000 hour rule says that you need 10000 hours of intensive practice to achieve mastery of complex skills. Perhaps if you could magically pull off this amount of practice in six months, who knows? Ideally though, you need much more than six months to gain expertise. However, if you put in the required work, you’ll be well on your way to a very promising career by the end of this training.',
    whyShould: "With the rising wave of the technology revolution across the world, software development has become one of the most in-demand skills of the 21st century. There is a shortage of talent as there are more available jobs than qualified candidates. Top tech companies globally are constantly recruiting, with average annual salaries ranging up to about $80,000 to $90,000, and the opportunity to work remotely. To this effect, dedicating a few months or years to build skills in software development is definitely a great decision that can set you up for an exciting and rewarding career.",
    gain: "<li>You will learn in-demand skills that are required by top tech companies around the world.</li> <li> You will be taught by highly skilled software professionals of international repute.</li> <li> You will get mentorship and career guidance to help you land your first developer role.</li> <li>You will be pushed beyond your limits as you launch into a new career trajectory and shoot for world class relevance.</li>   <li> You can literally go from your first line of code to your first developer role in six months if you put in the work.</li>",
    duration: "The training will run in two phases for a total of six months. Phase One (Frontend Engineering) three months, and Phase Two (Backend Engineering) three months.",

  },
  {
    id: 2,
    name: "Material Balance Analytics Training",
    description:
      "This training course is designed to equip participants with the understanding of reservoir performance analysis and introduces them to traditional material balance methods in assessing reservoir performance as well as the application of python programming in reservoir performance analysis. ",
    image: reservoir,
    price: 2000,
    // "location": [{place: "virtual", price: 2000, link: "https://paystack.com/buy/reservoir-performance-analysis-rbwxkf"}, {place: "Lagos", price: 4000, link: "https://paystack.com/buy/reservoir-performance-analysis-rbwxkf"}, {place: "Aberdeen & Malaysia", price: 5000, link: "https://paystack.com/buy/reservoir-performance-analysis-rbwxkf"}],
    objectives:
      "<li> Develop dexterity in reservoir performance analysis </li> <li>Understand the physics underpinning material balance and decline curve analysis </li> <li>Use material balance and DCA methods to efficiently monitor the reservoir performance</li> <li>Determine the parameters that impact well/reservoir performance</li> <li>Use of fractional flow equations </li> <li> Use python programming to perform reservoir analysis</li>",
    trainingFor:
      "This training is structured to provide an accelerated learning path for engineers who aspire to have a firm grip on material balance.",
    professionals:
      "<li>Reservoir engineers</li> <li>Geologists</li> <li>Petrophysicists</li> <li>Production Technologists </li> <li>Production Operators</li> <li>Facility Engineers</li> <li>Drilling/Completions Engineers</li",
    participantLearn:
      "<li>State the basic principle of material balance analysis and the principles underpinning its equations</li><li>Identify the data that is needed to apply the material balance equation and the uncertainties associated with data</li> <li>Describe the purpose of the material balance technique to estimate the initial hydrocarbons in place, determine drive mechanisms, carryout fractional flow matching, perform history matching and material balance prediction etc.</li> <li>Automate material balance analysis using python programming</li>",
    presentation:
      "This CypherCrescent industry school course combines structured and focused presentations that will be delivered via a series of online lectures with carefully selected case studies to help engrain practical applications of course contents in asset management. The course is designed for the specific needs of clients/ participants.",
    why: "This training course is designed to equip participants with the understanding of reservoir performance analysis and introduces them to traditional material balance methods in assessing reservoir performance as well as the application of python programming in reservoir performance analysis. The course will cover data quality check, PVT modelling, pressure history matching and fractional flow history matching amongst other things.",
    // "link": "https://paystack.com/buy/test-kymzmz"
    link: "https://paystack.com/buy/reservoir-performance-analysis-rbwxkf",
    instructors: [
      {
        id: 1,
        name: "ThankGod Egbe",
        profile:
          "<p>ThankGod Egbe is the CEO and Technical Director of CypherCrescent Limited. He drives innovation and the product development vision of the company leveraging his core expertise in petroleum engineering, applied mathematics & software development. Prior to CypherCrescent, ThankGod spent 10 years at Shell as a senior reservoir engineer. During this period, he was deployed to different locations including Shell International in Netherlands where he served as a global consultant for Integrated Production System Modelling.</p> <p>He was a designated global focal point for OFM, facility and reservoir model coupling (MoReS/Eclipse/CMG-GAP), and global subject matter expert in Integrated Production System Modelling, providing expert advisory service for over 12 countries covering all the continents of the world.</p>",
        image: tg,
      },
      {
        id: 2,
        name: "Fidelis Tendo",
        profile:
          "<p>Fidelis Tendo is currently a Director of Technical Operations and MD CypherCrescent UK.  He has over thirty (30) years of experience in Petroleum Engineering and Production Operations in the Oil and Gas Industry of which most of which were with The Royal Dutch Shell Group (Shell). He is an expert in subsurface modelling, field development planning and execution, wells and reservoir & facility management (WRFM) and production system optimisation (PSO).</p> <p>As principal reservoir engineer for Shell UK, he was the Technical Authority (TA) for all reservoir engineering technical work for the Shell Operated fields in the North Sea and supervised, coached, and mentored petroleum engineers on subsurface issues..</p>",
        image: francis,
      },
      {
        id: 3,
        name: "David Ogbe",
        profile:
          "<p>David Ogbe is a Professor Emeritus, at the University of Alaska. He has over 35-year experience which cuts across academia, research, and the oil and gas industry. Prof. Ogbe has published 100s of technical papers in his areas of specialisation: oil and gas reservoir engineering, well testing, reservoir simulation, reservoir characterization, and formation evaluation, and large-scale reservoir simulation on high-performance computers.</p> <p>He has worked as a lead reservoir engineer with Schlumberger and has served as an associate director of the Petroleum Development (Research) Laboratory, and coordinator of the Coalbed Methane Research Program at the University of Alaska Fairbanks, USA.</p>",
        image: davidogbe,
      },
    ],
    registerLink: "https://forms.office.com/r/M5NiHtU6XY",
    refundPolicy:
      "<p>Cancellations made 14 days or earlier to course commencement date will receive a 100% refund of net amount received. For cancellations made within 8 - 13 days earlier to course commencement date, 50% refund of net amount received shall apply.</p> <p>Refund shall not apply for cancellations made within 7 days to course commencement date.</p> <p>Please note that CypherCrescent shall not be responsible for refund of statutory and other bank charges incurred during initial payment. CypherCrescent shall not be responsible for refund of statutory and other bank charges incurred during initial payment.</p>",
  },
  {
    id: 3,
    name: "Programming with C#",
    description:
      "Computer Programming with C# course features up-to-date outline to help its participants learn the latest technology stack in developing modern and high performing software solutions.",
    image: cProgramming,
    price: 2000,
    objectives:
      "<li>Build a scientific application as well as create DLL (Dynamic Link Library) resources which can be integrated across multiple programs.</li> <li>Build a WPF application integrated with robust database management for interactive resource management.</li>",
    trainingFor:
      "This training is structured to provide an accelerated learning path for engineers who aspire to have a firm grip of petroleum engineering.",
    presentation:
      "The Course will be hybrid, to hold both physically and virtually in a location that will be communicated to the participants.",
    professionals:
      "<li>Pre-University student</li> <li>Recent graduates</li> <li>NYSC members</li> <li>Young professionals starting in their career</li> <li>C# enthusiast</li>",
    // "link": "https://paystack.com/buy/well-performance-analysis-qcmooi"
    link: "https://paystack.com/buy/well-performance-analysis-krglzk",
    why: "Computer Programming with C# course features up-to-date outline to help its participants learn the latest technology stack in developing modern and high performing software solutions.",
    instructors: [],
  },
  {
    id: 4,
    name: "Machine Learning for Beginners",
    description:
      "This course explores the basic concepts and techniques of python programming, Machine Learning fundamentals and the industrial application of machine learning in core areas of petroleum engineering like drilling, reservoir engineering, well integrity etc.",
    image: machineLearning,
    price: 1000,
    trainingFor:
      "This training is for individuals with little to no knowledge of machine learning. The course will equip you with the necessary skills to solve modern problems with technology and excel in the contemporary workplace, irrespective of your industry or sphere of endeavour.",
    // "objectives": "<li>Provide participants with basic python programming concepts  </li> <li>Provide participants with firm understanding of machine learning algorithms and their applications</li> <li>Demonstrate machine learning real life applications in petroleum engineering</li> <li>Provide students with hands-on that will enable them build machine learning models </li>",
    objectives:
      "<li>You will develop skills in scientific computing and machine learning to tackle real-world problems.</li> <li>Even without any previous programming experience, you will be able to “train” machines to make important decisions with a high level of accuracy.</li> <li>You will get clarity on the key differences between traditional programming and machine learning, and what makes machine learning unique.</li> <li>You will work on at least two real-world projects from end to end, individually and with a group of fellow trainees.</li> <li>You will be equipped to start a career in machine learning, deep learning, computer vision, or other related tech fields.</li> <li>The problem-solving and algorithmic thinking skills developed from the course will better position you to tackle non-machine-learning problems within and outside the workplace.</li> <li>You will understand data structures and the complexities behind them, which you can apply to other programming languages and other spheres of endeavour.</li>",
    professionals:
      "<li>Pre-University student</li> <li>Recent graduates</li> <li>NYSC members</li> <li>Young professionals starting in their career</li> <li>Machine learning enthusiast</li>",
    link: "https://paystack.com/buy/machine-learning-rmocbz",
    why: "With the technology revolution of the 21st century and more so in recent years, we have seen a tremendous increase in the number of machine learning jobs and machine learning applications across practically every field of endeavour.<br> Our Machine Learning for Beginners course was specially designed by CypherCrescent industry school to fill this gap. This course exposes beginners to the fundamentals of machine learning from first principles. Participants are taught how to “train” a computer to make independent decisions and predictions with a high level of accuracy without being directly programmed to do so.",
    presentation:
      "The training uses a fine blend of the best learning techniques to ensure that you get maximum value. These include visual PowerPoint presentations, individual and group class activities, hands-on projects, real-world case studies, and real-time follow-up with course facilitators/mentors.",
    instructors: [],
  },
];
export const otherPrograms = [
  {
    id: 1,
    name: "Sustainable Leadership Workshops",
    description:
      "Our executive leadership workshop course will provide you with the skills to build professional competencies to thrive as an executive or leader in the energy and digital technology industry.",
    image: leadership,
    price: 10000,
    location: "PortHarcourt",
    objectives:
      "<li>Develop the right mindset towards the implementation of energy transition strategies</li> <li>Know the key drivers for the energy transition.</li> <li>Understand the role of modern technology in staying profitable in this era</li> <li>Gain an understanding of business opportunities such as carbon capture, utilization, and storage (CCUS) from the energy transition.</li> <li>Be equipped with digital engineering and fourth industrial revolution (4IR) skills</li>",
    trainingFor:
      "This workshop has been prepared for a wide range of professionals in the energy and digital technology industry.",
    professionals:
      "<li>Executive leaders or managers, and professionals who will likely take up executive positions in no distant time.</li> <li>Professionals who create and/or implement corporate strategy</li> <li>Professionals who need a wider understanding of leadership and management principles</li> <li>Technical personnel taking up roles in staff management positions</li>",
    link: "https://paystack.com/buy/petroleum-engineering-bootcamp-izzcme",
    why: "Our executive leadership workshop course will provide you with the skills to build professional competencies to thrive as an executive or leader in the energy and digital technology industry. The workshop is practically designed and focuses on leadership skill development and building management capacities, that will position you to effectively lead your organization to meet present and future energy demands.The workshop is designed for industry leaders in the oil and gas sector who wish to have a firm grasp of environment, social and corporate governance (ESG), climate change, energy transition and the role of digitalisation in positioning their organisations for future energy demands. In this workshop, practical approaches to minimize the carbon footprints of oil and gas operations will be discussed.",
    presentation:
      "This workshop combines organised and stimulating presentations together with interactive facilitator lead discussion sessions supporting each of the topics.Participants will be provided with comprehensive Case studies, small group work, and discussions of topics covered with relevant exercises and feedback that will be used to facilitate learning.",
    instructors: [],
  },
  {
    id: 2,
    name: "Petroleum Engineering & Subsurface Integration Boot Camp",
    description:
      "The course is designed, specifically, to help participants gain the required knowledge and integrated understanding of the different geoscience and petroleum engineering disciplines to deliver value across the asset management life cycle.",
    image: bootcamp,
    price: 2000,
    objectives:
      "<li>Appreciate and understand the role of each discipline in oil & gas operations and </li> <li>Understand the concepts of integrated petroleum asset models and adopt best practices in asset management </li> <li>Understand the industry-standard workflow for the identification, screening and prioritisation of production optimisation opportunities </li> <li>Gain proficiency in diagnosing well problems and proposing appropriate well intervention strategies</li> <li>·	Realise how to plan field development and monitor asset performance</li>",
    trainingFor:
      "This training is structured to provide an accelerated learning path for engineers who aspire to have a firm grip of petroleum engineering.",
    presentation:
      "The Course will be hybrid, to hold both physically and virtually in a location that will be communicated to the participants.",
    professionals:
      "<li>Pre-University student</li> <li>Recent graduates</li> <li>NYSC members</li> <li>Young professionals starting in their career</li> <li>C# enthusiast</li>",
    // "link": "https://paystack.com/buy/well-performance-analysis-qcmooi"
    link: "https://paystack.com/buy/well-performance-analysis-krglzk",
    why: "CypherCrescent Academy invites you to participate in the all-new Integrated Reservoir &Asset Management Bootcamp. The course is designed, specifically, to help participants gain the required knowledge and integrated understanding of the different geoscience and petroleum engineering disciplines to deliver value across the asset management life cycle.The course will be delivered via a series of online lectures with carefully selected case studies to help engrain practical applications of course contents in asset management. The course is designed for the specific needs of clients/ participants.",
  },
  {
    id: 3,
    name: "Introduction to Exploration and Production Business",
    description:
      "This module is designed to support participants in developing a deeper understanding of the role of geoscience and petrophysics in identifying new opportunities, exploring, developing, and managing oil & gas assets to generate value for the enterprise.",
    image: explorationBus,
    price: 3500,
    location: "Lagos/Uyo",
    objectives: "",
    trainingFor:
      "This training is structured to provide an accelerated learning path for engineers who aspire to have a firm grip of petroleum engineering.",
    professionals:
      "<li>Geologists</li> <li>Petrophysicist</li> <li>Reservoir engineers</li> <li>Technical project managers</li> <li>Exploration managers</li>",
    presentation: "",
    availableSoon: true,
  },
  {
    id: 4,
    name: " Exploration and Production Contracts – A Practical Approach",
    description:
      "This course brings home the comprehensive analysis of the Nigerian fiscal regime and its allocation mechanics under the PSC.",
    image: exploration,
    price: 3500,
    location: "Lagos/Uyo",
    objectives: "",
    trainingFor:
      "This training is structured to provide an accelerated learning path for engineers who aspire to have a firm grip of petroleum engineering.",
    professionals:
      "<li>Technical, Commercial and Contract managers</li> <li>Policy Makers and Regulators</li> <li>Legal Practitioners </li> <li>Technical Project Managers</li> <li>Exploration Managers</li>",
    presentation: "",
    availableSoon: true,
  },
  {
    id: 5,
    name: "SEPAL Business Forecasting Workshop",
    description:
      "This intensive 3-day course is designed to introduce participants to the concept of production forecast optimisation. ",
    image: sepalBus,
    price: 3500,
    location: "Lagos/Uyo",
    objectives: "",
    trainingFor:
      "This intensive 3-day course is designed to introduce participants to the concept of production forecast optimisation. ",
    professionals:
      "<li>Geologists</li> <li>Petrophysicist</li> <li>Reservoir engineers</li> <li>Technical project managers <li>Exploration managers</li>",
    presentation: "",
    availableSoon: true,
  },
  {
    id: 6,
    name: "Oil & Gas Management Skills for Supervisors and Managers ",
    description:
      "This 5-day training course, Oil & Gas Management for Supervisors and Managers is designed to equip workers in managerial, supervisory, and regulatory roles with requisite knowledge and skills to effectively oversee oil and gas business.",
    image: oilMgt,
    price: 3500,
    objectives: "",
    trainingFor:
      "This training is structured to provide an accelerated learning path for engineers who aspire to have a firm grip of petroleum engineering.",
    professionals:
      "<li>Asset Managers</li> <li>Investment Analysts</li> <li>Finance Managers<li> <li> Engineers <li> <li>Technical project managers</li> <li>Anyone with an understanding of exploration and production economics<li>",
    presentation: "",
    availableSoon: true,
  },
  {
    id: 7,
    name: "Production Geology for Asset Management",
    description:
      "This course was designed to support participants in developing a deeper understanding of the role of geoscience and petrophysics in identifying new opportunities, exploring, developing, and managing oil & gas assets to generate value for the enterprise",
    image: geology,
    price: 3500,
    location: "Lagos/Uyo",
    objectives: "",
    trainingFor:
      "This training is structured to provide an accelerated learning path for engineers who aspire to have a firm grip of petroleum engineering.",
    professionals:
      "<li>Geologists<li> <li>Petrophysicist</li> <li>Reservoir engineers<li> <li>Technical project managers<li> <li>Exploration managers</li>",
    presentation: "",
    availableSoon: true,
  },
  {
    id: 8,
    name: "WRFM for Asset Value Optimisation ",
    description:
      "This training course will offer a concise but in-depth understanding of asset surveillance, data acquisition strategy plan and production system optimisation. Well, reservoir and facility management is the practice of extracting optimum value from oil and gas assets via systemic understanding of wells, reservoirs & facilities. ",
    image: wrfmOpt,
    price: 3500,
    location: "Lagos/Uyo",
    objectives: "",
    trainingFor:
      "This training is structured to provide an accelerated learning path for engineers who aspire to have a firm grip of petroleum engineering.",
    professionals:
      "	<li>Reservoir Engineers</li> <li>Geologists</li> <li>Petrophysicist</li> <li>Production Technologists</li> /<li>Production Operators</li> <li>Facility Engineers</li> <li>Drilling/Completions Engineers</li>",
    presentation: "",
    availableSoon: true,
  },
  {
    id: 9,
    name: "Production System Optimisation for Operational Efficiency ",
    description:
      "This training is designed to expose participants to typical production operation challenges and equip them with the requisite troubleshooting skills required to tackle the problems with little or no supervision",
    image: prodSystem,
    price: 3500,
    location: "Lagos/Uyo",
    objectives: "",
    trainingFor:
      "This training is structured to provide an accelerated learning path for engineers who aspire to have a firm grip of petroleum engineering.",
    professionals:
      "<li>Geologists</li> <li>Petrophysicist</li> <li>Reservoir engineers</li> <li>Production Technologists</li> <li>Asset managers</li>",
    presentation: "",
    availableSoon: true,
  },
  {
    id: 10,
    name: "Production System Optimisation for Operational Efficiency ",
    description:
      "This training is designed to expose participants to typical production operation challenges and equip them with the requisite troubleshooting skills required to tackle the problems with little or no supervision",
    image: landD,
    price: 4500,
    objectives: "",
    trainingFor:
      "This training is structured to provide an accelerated learning path for engineers who aspire to have a firm grip of petroleum engineering.",
    professionals:
      "<li>Geologists</li> <li>Petrophysicist</li> <li>Reservoir engineers</li> <li>Production Technologists</li> <li>Asset managers</li>",
    presentation: "",
    availableSoon: true,
  },
];

export const news = [
  {
    id: 1,
    tip: "CypherCrescent Website",
    title:
      "The Long-awaited CypherCrescent Learning and Development website is Live",
    shortDescription:
      "CypherCrescent Learning and Development Limited is pleased to announce the launch of her new website. Our website will serve as a digital space for us to provide our customers and visitors with an easier way to learn more about our learning programs and be updated with our news and instructional resources",
    body: "CypherCrescent Learning and Development Limited is pleased to announce the launch of her new website.Our website will serve as a digital space for us to provide our customers and visitors with an easier way to learn more about our learning programs and be updated with our news and instructional resources",
    image: websiteLaunch,
  },
  {
    id: 2,
    tip: "Industry school",
    title:
      "CypherCrescent launches industry school, appoints Tombari as Managing Director of its new subsidiary",
    shortDescription:
      "As a part of CypherCrescent’s commitment to providing innovative business intelligence solutions and ensuring human capacity development, the company is proud to announce the commencement of our technology and energy industry school...",
    body: "As a part of CypherCrescent’s commitment to providing innovative business intelligence solutions and ensuring human capacity development, the company is proud to announce the commencement of our technology and energy industry school, which will be managed under CypherCrescent Learning & Development Limited (CypherCrescent L&D), a new subsidiary of CypherCrescent Group.",
    image: appoint,
  },
];

export const partners = [
  {
    id: 1,
    name: "aust",
    image: aust,
  },
  {
    id: 2,
    name: "coe",
    image: coe,
  },
  {
    id: 3,
    name: "coou",
    image: coou,
  },
  {
    id: 4,
    name: "eroton",
    image: eroton,
  },
  {
    id: 5,
    name: "futminna",
    image: futminna,
  },
  {
    id: 6,
    name: "futo",
    image: futo,
  },
  {
    id: 7,
    name: "idsl",
    image: idsl,
  },
  {
    id: 8,
    name: "ips",
    image: ips,
  },
  {
    id: 9,
    name: "nd-western",
    image: ndWestern,
  },
  {
    id: 10,
    name: "nile",
    image: nile,
  },
  {
    id: 11,
    name: "nnpc",
    image: nnpc,
  },
  {
    id: 12,
    name: "pti",
    image: pti,
  },
  {
    id: 13,
    name: "spe",
    image: spe,
  },
  {
    id: 14,
    name: "uniben",
    image: uniben,
  },
  {
    id: 15,
    name: "unilag",
    image: unilag,
  },
  {
    id: 16,
    name: "uniport",
    image: uniport,
  },
];
