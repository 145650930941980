import * as React from "react";
import { Route, Routes } from "react-router";
import './index.css';
import Home from "./screens/Index"
import Gallery from "./screens/Gallery/Gallery"
import Contact from "./screens/Contact/Contact";
import About from "./screens/About/About";
import News from "./screens/News/News";
import SingleNews from "./screens/SingleNews/SingleNews";
import Faqs from "./screens/Faqs/Faqs";
import Program from "./screens/Program/Program";
import Programs from "./screens/Programs/Programs";
import Privacy from "./screens/Privacy/Privacy";

const initClarity = () => {
  (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "dco94968sc");
}

class App extends React.Component {
  componentDidMount() {
    initClarity();
  }

  render() {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/news" element={<News />} />
        <Route path="/single-news" element={<SingleNews />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/program" element={<Program />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="*" element={<Home />} />
      </Routes>
    )
  }
}

export default App;